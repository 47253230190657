import React from 'react';
import '../styles/TermsOfService.css';

const TermsOfServicePage = () => {
  return (
    <div className="policy-page-container">
      <h1 className="policy-page-title">Terms of Service</h1>
      <div className="policy-content">
        <p>
          By accessing, using, or purchasing any service, product, or feature (collectively, "Services") provided by Zombie Mini Arena (referred to as "We," "Us," or "Our"), you agree to be bound by these Terms of Service ("Terms"). If you disagree with any part of these Terms, you must cease use of Our Services immediately. Your continued use constitutes acceptance of these Terms.
        </p>

        <h2>1. Use of Services</h2>
        <ul>
          <li>You agree to use Our Services only for lawful and legitimate purposes in compliance with these Terms and applicable laws.</li>
          <li>You must not use Our Services for activities including, but not limited to, harassment, spam, malicious hacking, or any other unethical or illegal behavior.</li>
          <li>You are responsible for maintaining the confidentiality of all associated account credentials.</li>
          <li>No person under 13 years of age may use Our Services.</li>
          <li>We reserve the right to suspend, restrict, or terminate your access to Our Services at any time and for any reason, including violations of these Terms.</li>
        </ul>

        <h2>2. Payments and Refunds</h2>
        <ul>
          <li>Our Services may include digital products such as virtual currency, in-game items, or other features. These purchases are non-transferable and non-refundable.</li>
          <li>All sales are final. No refunds, returns, or exchanges will be offered under any circumstances, including but not limited to outages, errors, or changes to the Services.</li>
          <li>Payments are processed securely through trusted third-party providers including Stripe, Discord, and others. For data usage details, please see Our Privacy Policy (https://zma.lol/privacy-policy).</li>
          <li>You are solely responsible for ensuring transactions associated with your account are legitimate.</li>
          <li>In the event of a payment processing error, We are not liable for any delay, failure, or loss.</li>
        </ul>

        <h2>3. Service Availability</h2>
        <ul>
          <li>Our Services are provided strictly "as-is" and "as-available," without any warranties, express or implied.</li>
          <li>We do not guarantee that Our Services will be available at all times or that they will be free from errors, interruptions, or other issues.</li>
          <li>Service outages, whether scheduled or unscheduled, do not entitle you to refunds or compensation of any kind.</li>
          <li>We are not responsible in any manner for interruptions to or discontinuation of Our Services.</li>
          <li>We reserve the right to modify, suspend, or discontinue any part of Our Services without prior notice.</li>
        </ul>

        <h2>4. Liability and Disclaimers</h2>
        <ul>
          <li>You expressly agree that We are not liable for any damages, whether direct, indirect, incidental, consequential, or punitive, arising from your use of Our Services.</li>
          <li>We are not responsible for any loss of data, unauthorized access to your account, or damages associated with the usage of Our Services.</li>
          <li>You acknowledge that the use of Our Services involves inherent risks, including but not limited to bugs, data leaks, and potential interruptions.</li>
          <li>To the fullest extent permitted by law, We disclaim all warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
          <li>You agree to indemnify and hold Us harmless from any claims, damages, or expenses arising from your use of Our Services.</li>
        </ul>

        <h2>5. Changes to These Terms</h2>
        <p>
          We reserve the right to update or modify these Terms at any time without notice. Any changes will be effective immediately upon posting to this page. Continued use of Our Services after changes are posted constitutes your acceptance of the revised Terms.
        </p>

        <h2>6. Limitation of Service</h2>
        <ul>
          <li>We reserve the right to limit or terminate access to Our Services for any user at Our sole discretion.</li>
          <li>Access to certain features or content may require additional payments or subscriptions, which are also non-refundable.</li>
        </ul>

        <h2>7. Contact Information</h2>
        <p>
          If you have questions or concerns regarding these Terms, you can contact us at zmaowner@gmail.com or directly via Discord (https://discord.gg/zma).
        </p>

        <p>
          By using Our Services, you acknowledge that you have read, understood, and agreed to these Terms in their entirety.
        </p>
      </div>
    </div>
  );
};

export default TermsOfServicePage;